import * as React from "react"
import { useState, useEffect } from "react";

const ExteriorComponent = ({ trimColor, windowColor, sidingColor, featureVisibility }) => {

    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const initialWidth = 904
    const initialHeight = 399
    // Calculate the image size based on window size
    const imageSize = {
        width: Math.min(windowSize.width * 0.8, initialWidth),
        height: Math.min(windowSize.height * 0.8, initialHeight),
    };

    return (
        <>
            {featureVisibility.customizeExterior &&
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={imageSize.width}
                    height={imageSize.height}
                    version="1.1"
                    viewBox="0 0 1383.298 900"
                    id="svg62"
                >
                    <g>
                        <path
                            fill="#fff"
                            strokeWidth="8.727"
                            d="M-4.269 -17.075H1393.7549999999999V909.2489999999999H-4.269z"
                        ></path>
                    </g>
                    <g fill={sidingColor} strokeWidth="8.727">
                        <path d="M917.787 87.51l4.268 723.557 386.325-46.956-10.672-638.182z"></path>
                        <path d="M866.561 87.51L437.55 140.87l4.27 610.434 422.608 59.763 4.269-608.3-117.392 10.672-2.134 337.233-166.482-2.134v-315.89l296.68-21.344z"></path>
                        <path d="M83.241 695.81l181.423 29.882-2.134-554.941-177.154 19.21-2.135 456.758 134.467 6.404V522.925l-96.048-4.269 4.269-236.917 87.51-12.806v258.26L76.838 631.78z"></path>
                    </g>
                    <g display="inline" transform="translate(-441.41 -51.719)">
                        <path
                            stroke="#000"
                            strokeWidth="4.2"
                            d="M1332.89 87.686l-1.797 817.125 35.918-5.388-1.796-806.35z"
                            className="frame"
                            fill={trimColor}
                        ></path>
                        <path
                            stroke="#000"
                            strokeWidth="4.2"
                            d="M1370.603 145.154l396.889 46.693-5.388-43.101-398.685-57.468z"
                            className="frame"
                            fill={trimColor}
                        ></path>
                        <path
                            stroke="#000"
                            strokeWidth="4.2"
                            d="M1731.574 188.255l8.98 659.087 26.938-1.795-3.592-651.904z"
                            className="frame"
                            fill={trimColor}
                        ></path>
                        <path
                            stroke="#000"
                            strokeWidth="4.2"
                            d="M1365.215 854.526l371.747-48.489 5.388 43.101-373.543 50.285z"
                            fill={trimColor}
                        ></path>
                        <path
                            stroke="#000"
                            strokeWidth="4.2"
                            d="M1327.502 87.686l-818.92 131.099 1.795 37.713 820.716-113.14z"
                            className="frame"
                            fill={trimColor}
                        ></path>
                        <path
                            stroke="#000"
                            strokeWidth="4.2"
                            d="M1302.36 145.154l-1.796 707.576 32.325 5.388-1.795-714.76z"
                            fill={trimColor}

                        ></path>
                        <path
                            stroke="#000"
                            strokeWidth="4.2"
                            d="M1331.093 858.118l-1.795 46.693-797.37-132.895-1.796-34.122z"
                            fill={trimColor}
                        ></path>
                        <path
                            stroke="#000"
                            strokeWidth="4.2"
                            d="M530.132 768.324l7.183-515.417-26.938 5.387 1.796 506.438z"
                            fill={trimColor}
                        ></path>
                        <path
                            stroke="#000"
                            strokeWidth="4.2"
                            fill={windowColor}
                            d="M693.557 233.152l1.796 529.784 192.159 28.734V204.418l-179.588 23.346 1.796 21.551 167.016-25.142 1.796 545.947-167.017-26.938-1.795-515.418z"
                        ></path>
                        <path
                            stroke="#000"
                            strokeWidth="4.2"
                            fill={windowColor}
                            d="M781.555 240.336l1.796 64.651-71.836 3.592-1.795 26.938 71.835-8.98 3.591 429.216 12.572 3.591-1.796-431.01 80.814-7.184v-23.346l-80.814 7.183.452-67.351z"
                        ></path>
                        <path
                            stroke="#000"
                            strokeWidth="4.2"
                            fill={windowColor}
                            d="M1015.019 278.049l5.388 325.054 172.404 8.98-1.796-351.993-156.242 16.163 1.796 26.938 141.875-14.367 3.592 307.096-145.467-10.776-1.796-308.89z"
                        ></path>
                        <path
                            stroke="#000"
                            strokeWidth="4.2"
                            fill={windowColor}
                            d="M555.274 324.213v259.135l111.344 5.388-5.387-278.361-88.11 10.83-.311 26.114 77.646-4.618 5.387 222.689-87.998-5.388 5.388-238.852z"
                        ></path>
                    </g>
                    <g
                        fill="none"
                        stroke="#000"
                        strokeWidth="4.2"
                        transform="translate(-441.41 -51.719)"
                    >
                        <path d="M887.459 239.174l413.074-50.07 1.564 45.376-416.203 39.117 1.565 32.858 128.303-14.082v34.423L887.459 337.75l.173 34.857 129.26-8.258.173 37.64-129.26 3.042-.346 32.858 131.432-4.694v35.988H887.11l.35 32.858 131.432 4.694v35.987l-131.432-3.129v31.293l132.909 5.129.088 34.684-132.997-8.519v35.987l413.074 28.165 1.564 40.681-414.292-38.682-.346 33.988 413.074 42.246v43.811l-414.64-54.763v32.858l416.204 56.328-1.564-197.149-280.077-15.647v-4.694l175.244 7.824-1.565-31.294 106.398 3.13 1.564-39.117-111.091-3.13 3.129-37.552 109.527 3.13-1.565-43.811-107.962 4.694-1.565-37.553 109.527-3.129V387.82l-109.527 4.694 1.565-39.117 107.962-4.694V308.02l-109.527 9.388-3.13-40.681 111.093-7.824v3.13"></path>
                        <path d="M693.439 264.21l-156.254 16.957-.04 31.547 156.724-17.642v30.16l-30.159 4.694v25.034l30.152-1.859-.111 33.48-30.217 1.528.819 25.501 29.894-2.27.757 31.242-30.776 1.275 1.047 25.325 29.262-1.69.467 32.164-29.73 2.384.519 34.713 29.324-2.55-.113 27.294h-29.73l.267 26.157 29.669 3.13.03 30.201-162.963-10.983-1.564 31.294 164.29 10.953 1.565 31.293-165.855-20.34v31.293l165.855 17.211v29.729l-165.855-23.47 2.58-135.619 22.454-1.392-.508-27.52-22.454 2.78 1.057-24.445 21.419-1.931-.619-32.754-20.8 1.826 1.016-23.216 20.528-3.429-.521-24.778-19.458 1.354v-25.035l19.569-1.069.84-29.242-20.196.836-.213-26.853 19.45-.764.119-28.985-19.396 1.585"></path>
                        <path d="M1366.249 189.105l367.699 37.552 1.564 35.988-369.476-32.859.213 40.682 369.263 26.6 1.565 37.552-369.69-25.142-2.703 37.659 367.7 15.647 3.128 40.681-369.263-17.211-1.565 39.117 369.264 7.823 1.564 35.988-369.263-4.694-1.565 39.116 372.393-3.129-3.13 40.682-369.263 4.694"></path>
                        <path d="M1364.684 584.968l370.828-14.083-4.694 37.553-366.134 14.082V663.2l372.393-20.34-3.13 34.422-367.698 26.6-1.565 42.246 372.393-32.858-1.565 40.681-369.263 31.294-1.565 37.552 373.958-43.81"></path>
                        <path d="M1046.163 311.15l-1.586 264.734 55.677-.609 5.282-269.754z"></path>
                        <path d="M1119.03 306.455l-3.412 271.928h54.742l-1.26-278.186z"></path>
                        <path d="M577.653 354.96l-4.694 200.258 31.293-1.544-.955-201.213z"></path>
                        <path d="M612.748 354.33l2.153 199.018 32.249 1.586-5.02-203.103z"></path>
                    </g>

                </svg>
            }
        </>
    )
}

export default ExteriorComponent;