import React, { useState } from 'react';

const ContactForm = ({totalPrice, selectedFeatures, svgElement, extElement}) => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phoneNumber: '',
        message: '',
        size: '',
        interior: svgElement,
        exterior: extElement,
        price: totalPrice,
        selectedFeatures: Object.keys(selectedFeatures).join(", ")
    });
    const [isSubmitting, setIsSubmitting] = useState(false);


    const [messageSent, setMessageSent] = useState(false);

    const url = process.env.REACT_APP_API_URL


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            const response = await fetch(url + '/api/info/submit-form', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.status === 200) {
                setMessageSent(true);
            } else {
                // Handle errors here
                console.error('Error submitting form');
            }
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    return (
        <div>
            {messageSent ? (
                <p className='whiteText'>Thank you for contacting us! We have received your message and will reach out shortly.</p>
            ) : (
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="name">Name:</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="email">Email:</label>
                        <input
                            type="email"
                            id="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="phoneNumber">Phone Number:</label>
                        <input
                            type="tel"
                            id="phoneNumber"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                        />
                    </div>
                    <div>
                        <label>Size:</label>
                        <select
                            name="size"
                            value={formData.size}
                            onChange={handleChange}
                            required
                        >
                            <option value="">Select a size</option>
                            <option value="2.45x5.95 studio">2.45m x 5.95m Studio</option>
                            <option value="2.45x5.95 office">2.45m x 5.95m Office</option>
                            <option value="3x5.95 office">3.00m x 5.95m Office</option>
                            <option value="3x5.95 studio">3.00m x 5.95m Studio</option>
                            <option value="3x8 office">3.00m x 8.00m Office</option>
                            <option value="3x8 studio">3.00m x 8.00m Studio</option>
                        </select>
                    </div>
                    {/* Add other form fields as needed */}
                    <div>
                        <label htmlFor="message">Message:</label>
                        <textarea
                            id="message"
                            name="message"
                            value={formData.message}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <button type="submit" disabled={isSubmitting || messageSent}>Submit</button>
                </form>
            )}
        </div>
    );
};

export default ContactForm;
