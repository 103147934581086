import './App.css';
import FlowManager from './components/FlowManager.jsx';
import FAQ from './views/FAQ.jsx';
import Return from './components/Return.jsx'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import React, { useState } from 'react';


function App() {

  const [navActive, setNavActive] = useState(false);
  const toggleNav = () => {
    setNavActive(!navActive);
  };

  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <h1>Outback Flats</h1>
          <nav className={navActive ? 'active' : ''}>
            <a href="https://outbackflats.com.au">Welcome</a>{" "}
            <Link href="/">Custom Designer</Link>{" "}
            <a href="https://outbackflats.com.au/about">About</a>{" "}
            <a href="https://outbackflats.com.au/gallery">Gallery</a>{" "}
            <a href="https://outbackflats.com.au/terms-and-conditions">Terms & Conditions</a>
          </nav>
          <button className="hamburger" onClick={toggleNav}>
            <div className="hamburger-line"></div>
            <div className="hamburger-line"></div>
            <div className="hamburger-line"></div>
          </button>

        </header>

        <Routes>
          <Route path="/" element={<FlowManager />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/return" element={<Return />} />
        </Routes>


      </div>
    </Router>
  );
}

export default App;
