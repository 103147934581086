import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';

const url = process.env.REACT_APP_API_URL
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);



const CustomerForm = ({ totalPrice, selectedFeatures, svgElement, extElement }) => {
  const [clientSecret, setClientSecret] = useState('');

  const getDefaultSvg = () => {
    return `
      <svg width="50" height="50" xmlns="http://www.w3.org/2000/svg">
        <rect width="50" height="50" style="fill: blue;" />
      </svg>
    `;
  };


  const saveSvgElementsToCloud = (svgElement, extElement) => {
    return fetch(url + "/api/svgs/save-svgs", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        svgElement: svgElement || getDefaultSvg(),
        extElement: extElement || getDefaultSvg()
      }),
    })
      .then(res => {
        if (!res.ok) {
          throw new Error(`HTTP error! status: ${res.status} ${res.body}`);
        }
        return res.json();
      })
      .then(data => {
        return data;
      })
      .catch(error => {
        console.error('Error in saveSvgElementsToCloud:', error);
        throw error;
      });
  }

  const saveSvgsAndCreateSession = async (totalPrice, selectedFeatures) => {
    try {
      // Save SVGs to cloud and get their URLs
      const responseData = await saveSvgElementsToCloud(svgElement, extElement);
      const { sessionId, svgUrl, extUrl } = responseData; // Extract data from the response
      console.log(svgUrl)
      console.log(extUrl)
      const selected = Object.keys(selectedFeatures).join(", ")
      console.log(selected)

      // Create Stripe checkout session with SVG URLs
      fetch(url + "/api/stripe/create-checkout-session", {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          sessionId,
          totalPrice,
          selected
        }),
      })
        .then(res => res.json())
        .then(data => setClientSecret(data.clientSecret))
        .catch(error => console.error('Error creating checkout session:', error));
    } catch (error) {
      console.error('Error in saveSvgsAndCreateSession:', error.message);
    }
  };

  useEffect(() => {
    saveSvgsAndCreateSession(totalPrice, selectedFeatures);
    // eslint-disable-next-line
  }, [svgElement, extElement]);




  // Save interior and exterior to database for ref in email
  return (
    <div id="checkout">
      {!clientSecret &&
        <h3 className='whiteText'>Loading, please wait.</h3>
      }
      {clientSecret && (
        <EmbeddedCheckoutProvider
          stripe={stripePromise}
          options={{ clientSecret }}
        >
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  )
};

export default CustomerForm;
