import React, { useState, useEffect } from 'react';

const FeatureComponent = ({ feature, price, visible, onFeatureToggle }) => {
    const [checked, setChecked] = useState(visible);

    useEffect(() => {
        if (visible !== undefined) {
            setChecked(visible);
        }
    }, [visible]);

    const handleCheckboxChange = () => {
        const newCheckedState = !checked;
        setChecked(newCheckedState);
        onFeatureToggle(feature, newCheckedState);
    };
    

    return (
        <div className="feature-item">
            <input
                type="checkbox"
                id={feature}
                className="feature-checkbox"
                checked={checked}
                onChange={handleCheckboxChange}
            />
            <label htmlFor={feature} className="feature-label">
                {feature} ({price} AUD)
            </label>
        </div>
    );
};

export default FeatureComponent;
