

import React from 'react';
import DesignerInstructions from '../components/DesignerInstructions';
import FeatureComponentList from '../components/FeatureComponentList';
import ColorSwatch from '../components/ColorSwatch';
import SvgComponent from '../components/SvgComponent';
import ExteriorComponent from '../components/ExteriorComponent';
import { useState, useEffect } from 'react';

const Designer = ({ totalPrice, setTotalPrice, featureVisibility, setFeatureVisibility, svgElement, setSvgElement, extElement, setExtElement }) => {
    const [selectedFeature, setSelectedFeature] = useState('floor'); // new state for selected feature
    const [selectedColor, setSelectedColor] = useState('#4f7663'); // Default color
    const [trimColor, setTrimColor] = useState('#fff');
    const [windowColor, setWindowColor] = useState('#fff');
    const [sidingColor, setSidingColor] = useState('#fff');
    useEffect(() => {
        const initialSvgElement = window.document.getElementById('svg74')?.outerHTML;
        if (initialSvgElement) setSvgElement(initialSvgElement);
        // eslint-disable-next-line
    }, []);
    useEffect(() => {
        // Update extElement whenever the relevant states change
        const updatedExtElement = window.document.getElementById('svg62')?.outerHTML;
        if (updatedExtElement) {
            setExtElement(updatedExtElement);
        }
        // eslint-disable-next-line
    }, [trimColor, windowColor, sidingColor]); // Dependencies array

    const defaultColors = ["#272528", "#282725", "#8f949d", "#7f7c67", "#737568", "#98999e", "#3f3b30", "#3e4036", "#32382d", "#333427", "#4f7663", "#6e7a98", "#2a3253", "#284685", "#2c4965", "#24327a", "#22242f", "#3c262a", "#9b9267", "#821b2a", "#d9cda3", "#e3d8be", "#a0937c", "#dfd9c5", "#a38455", "#C4B188"];
    const windowColors = ['white', 'black', 'grey', '#6F4E37'];
    const sidingColors = ["#272528", "#282725", "#8f949d", "#7f7c67", "#737568", "#98999e", "#3f3b30", "#3e4036", "#32382d", "#333427", "#4f7663", "#6e7a98", "#2a3253", "#284685", "#2c4965", "#24327a", "#22242f", "#3c262a", "#9b9267", "#821b2a", "#d9cda3", "#e3d8be", "#a0937c", "#dfd9c5", "#a38455", "#C4B188"];
    const trimColors = ["#272528", "#282725", "#8f949d", "#7f7c67", "#737568", "#98999e", "#3f3b30", "#3e4036", "#32382d", "#333427", "#4f7663", "#6e7a98", "#2a3253", "#284685", "#2c4965", "#24327a", "#22242f", "#3c262a", "#9b9267", "#821b2a", "#d9cda3", "#e3d8be", "#a0937c", "#dfd9c5", "#a38455", "#C4B188"];
    const colors = selectedFeature === 'window' ? windowColors
        : selectedFeature === 'trim' ? trimColors
            : selectedFeature === 'siding' ? sidingColors
                : defaultColors;


    const handleColorSelect = (color) => {
        switch (selectedFeature) {
            case 'trim':
                setExtElement(window.document.getElementById('svg62').outerHTML)
                setTrimColor(color);
                break;
            case 'window':
                setExtElement(window.document.getElementById('svg62').outerHTML)
                setWindowColor(color);
                break;
            case 'siding':
                setExtElement(window.document.getElementById('svg62').outerHTML)
                setSidingColor(color);
                break;
            default:
                setSelectedColor(color);
        }
        setSvgElement(window.document.getElementById("svg74").outerHTML)
    };


    const featurePrices = {
        bed: 0, // Bed (For display only)
        basin: 500, // Basin
        toilet: 300, // Toilet
        shower: 600, // Shower
        kitchenSink: 400, // Kitchen Sink
        firstWall: 500, // First Wall
        secondWall: 200, // Second Wall
        thirdWall: 200, // Third Wall
        fourthWall: 200, // Fourth Wall
        customizeExterior: 500 // Customize Exterior (White or Black Frame free)
    };

    const handleFeatureToggle = (featureName, isVisible) => {
        setFeatureVisibility(prevState => {
            const updatedState = {
                ...prevState,
                [featureName]: isVisible
            }
            // Calculate total price
            let price = 13000;
            for (const feature in updatedState) {
                if (updatedState[feature]) {
                    price += featurePrices[feature] || 0;
                }
            }

            // Update the total price state here
            setTotalPrice(price);
            setSvgElement(window.document.getElementById("svg74").outerHTML)
            return updatedState;
        });

    };
    return (
        <div>
            <div className="designer-container">

                <div className="designer-item">
                    <DesignerInstructions />
                </div>
                <div className="designer-item">
                    <FeatureComponentList
                        handleFeatureToggle={handleFeatureToggle}
                        featureVisibility={featureVisibility}
                    />
                </div>
            </div>
            <h1>{totalPrice} AUD</h1>
            <div className="button-container">
                <button
                    className={selectedFeature === 'floor' ? 'button-active' : ''}
                    onClick={() => setSelectedFeature('floor')}
                >
                    Floor Color
                </button>
                {featureVisibility.customizeExterior && (
                    <>
                        <button
                            className={selectedFeature === 'window' ? 'button-active' : ''}
                            onClick={() => setSelectedFeature('window')}
                        >
                            Window Color
                        </button>
                        <button
                            className={selectedFeature === 'siding' ? 'button-active' : ''}
                            onClick={() => setSelectedFeature('siding')}
                        >
                            Siding Color
                        </button>
                        <button
                            className={selectedFeature === 'trim' ? 'button-active' : ''}
                            onClick={() => setSelectedFeature('trim')}
                        >
                            Frame Color
                        </button>
                    </>
                )}
            </div>
            <ColorSwatch colors={colors} onColorSelect={handleColorSelect} selectedFeature={selectedFeature} />
            <SvgComponent selectedColor={selectedColor} featureVisibility={featureVisibility} className="component-spacing" />
            <ExteriorComponent trimColor={trimColor} windowColor={windowColor} sidingColor={sidingColor} featureVisibility={featureVisibility} />
        </div>
    );
};

export default Designer;