import React from 'react';
import CustomerForm from './CustomerForm';

const SubmitForm = ({totalPrice, selectedFeatures, svgElement, extElement}) => {
    return (
        <div>
            <CustomerForm totalPrice={totalPrice} selectedFeatures={selectedFeatures} svgElement={svgElement} extElement={extElement} />
        </div>
    );
};

export default SubmitForm;
