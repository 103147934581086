import React, { useState } from 'react';

const ColorSwatch = ({ colors, onColorSelect, selectedFeature }) => {
    const [selectedColor, setSelectedColor] = useState(null);

    const handleColorClick = (color) => {
        setSelectedColor(color);
        onColorSelect(color);
    };

    return (
        <div className="color-swatch">
            {colors.map(color => (
                <div 
                    key={color}
                    className={`color-block ${selectedColor === color ? 'selected' : ''}`}
                    style={{ backgroundColor: color }}
                    onClick={() => handleColorClick(color)}
                />
            ))}
        </div>
    );
};

export default ColorSwatch;
