import React from 'react';

const DesignerInstructions = () => {
    return (
        <div className="designer-instructions">
            <h3>*Page is under construction, prices displayed are estimates only until page is complete.*</h3>
            <h2>How to Use the Designer</h2>
            <ol>
                <li>
                    Select desired color scheme.
                </li>
                <li>
                    Select desired features.
                </li>
                <li>
                    Rotate an element to its desired orientation by double clicking/double tapping.
                </li>
                <li>
                    Click and drag to its desired location. Doors, walls, windows, kitchen, toilet, sink, bed, and shower are moveable.
                </li>

            </ol>
        </div>
    );
};

export default DesignerInstructions;
