import React, { useState } from 'react';
import Designer from '../views/Designer';
import ExtraOptions from './ExtraOptions';
import SubmitForm from './SubmitForm';
import ContactForm from './ContactForm'

const FlowManager = () => {
    const [step, setStep] = useState(1);
    const [totalPrice, setTotalPrice] = useState(13000);
    const [selectedFeatures, setSelectedFeatures] = useState({});
    const [svgElement, setSvgElement] = useState(null)
    const [extElement, setExtElement] = useState(null)
    const [featureVisibility, setFeatureVisibility] = useState({
        bed: false,
        basin: false,
        toilet: false,
        shower: false,
        kitchenSink: false,
        firstWall: false,
        secondWall: false,
        thirdWall: false,
        fourthWall: false,
        customizeExterior: false,
    });

    const resetState = () => {
        setStep(1);
        setTotalPrice(13000);
        setSelectedFeatures({});
        setSvgElement(null);
        setExtElement(null);
        setFeatureVisibility({
            bed: false,
            basin: false,
            toilet: false,
            shower: false,
            kitchenSink: false,
            firstWall: false,
            secondWall: false,
            thirdWall: false,
            fourthWall: false,
            customizeExterior: false
        });
    };


    const nextStep = () => {
        if (step < 3) setStep(step + 1);
    };

    const previousStep = () => {
        if (step > 1) setStep(step - 1);
    };

    const handleRequestInformation = () => {
        setStep(4);
    };

    const handleCheckoutNow = () => {
        setStep(3);
    };

    let stepComponent;
    switch (step) {
        case 1:
            stepComponent = <Designer totalPrice={totalPrice} setTotalPrice={setTotalPrice} featureVisibility={featureVisibility} setFeatureVisibility={setFeatureVisibility} svgElement={svgElement} setSvgElement={setSvgElement} extElement={extElement} setExtElement={setExtElement} />;
            break;
        case 2:
            stepComponent = <ExtraOptions totalPrice={totalPrice} setTotalPrice={setTotalPrice} selectedFeatures={selectedFeatures} setSelectedFeatures={setSelectedFeatures} />;
            break;
        case 3:
            stepComponent = <SubmitForm totalPrice={totalPrice} selectedFeatures={selectedFeatures} svgElement={svgElement} extElement={extElement} />;
            break;
        case 4:
            stepComponent = <ContactForm totalPrice={totalPrice} selectedFeatures={selectedFeatures} svgElement={svgElement} extElement={extElement} />;
            break;
        default:
            stepComponent = <Designer />;
    }
    return (
        <div className="designer-stepper">
            {stepComponent}

            <div className="stepper-controls">
                {step < 2 && <button onClick={nextStep}>Next</button>}
                {step > 1 && step < 4 && <button onClick={previousStep}>Previous</button>}
                {step === 2 && (
                    <>
                        <button onClick={handleRequestInformation}>Request Information</button>
                        <button onClick={handleCheckoutNow}>Submit Deposit</button>
                    </>
                )}
            </div>
            
            <div className="stepper-footer">
                <button className="reset-button" onClick={() => {
                    if(window.confirm('Are you sure you want to reset?')) {
                        resetState();
                    }
                }}>Reset</button>
            </div>
        </div>
    );
    
};

export default FlowManager;
