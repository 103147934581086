import React, { useState, useRef, useEffect } from "react";
import Draggable from "react-draggable";

const ImageComponent = ({ xlinkHref, width, height, x, y, visibility, clippath }) => {
    const [rotation, setRotation] = useState(0);
    const [dragPosition, setDragPosition] = useState({ x: 0, y: 0 });
    const lastTap = useRef(null);
    const imageRef = useRef(null);

    const handleDrag = (e, ui) => {
        const { x, y } = dragPosition;
        setDragPosition({
            x: x + ui.deltaX,
            y: y + ui.deltaY,
        });
    };

    const handleDoubleTap = () => {
        setRotation(prevRotation => prevRotation + 90);
    };

    useEffect(() => {
        const handleTouch = (e) => {
            const currentTime = new Date().getTime();
            const tapGap = currentTime - lastTap.current;
            if (lastTap.current && tapGap < 500 && tapGap > 0) {
                // Detected a double tap
                handleDoubleTap();
            }
            lastTap.current = currentTime;
        };

        const imageElement = imageRef.current;
        if (imageElement) {
            imageElement.addEventListener('touchend', handleTouch);
        }

        return () => {
            if (imageElement) {
                imageElement.removeEventListener('touchend', handleTouch);
            }
        };
    }, []);

    const transformOriginX = x + width / 2;
    const transformOriginY = y + height / 2;

    const combinedStyle = {
        transform: `translate(${dragPosition.x}px, ${dragPosition.y}px) rotate(${rotation}deg)`,
        transition: 'transform 0.1s',
        transformOrigin: `${transformOriginX}px ${transformOriginY}px`,
        display: 'inline-block',
    };

    return (
        <Draggable onDrag={handleDrag}>
            <image
                ref={imageRef}
                xlinkHref={xlinkHref}
                width={width}
                height={height}
                x={x}
                y={y}
                preserveAspectRatio="none"
                style={combinedStyle}
                clipPath={clippath}
                onDoubleClick={handleDoubleTap}
            />
        </Draggable>
    );
};

export default ImageComponent;