import React from 'react';
import FeatureComponent from './FeatureComponent';

const FeatureComponentList = ({handleFeatureToggle, featureVisibility}) => {
    return (
        <div>
            <FeatureComponent feature={"Bed (For display only)"} price={0} visible={featureVisibility.bed} onFeatureToggle={() => { handleFeatureToggle('bed', !featureVisibility.bed) }} />
            <FeatureComponent feature={"Basin"} price={500} visible={featureVisibility.basin} onFeatureToggle={() => { handleFeatureToggle('basin', !featureVisibility.basin) }} />
            <FeatureComponent feature={"Toilet"} price={300} visible={featureVisibility.toilet} onFeatureToggle={() => { handleFeatureToggle('toilet', !featureVisibility.toilet)}} />
            <FeatureComponent feature={"Shower"} price={600} visible={featureVisibility.shower} onFeatureToggle={() => { handleFeatureToggle('shower', !featureVisibility.shower) }} />
            <FeatureComponent feature={"Kitchen Sink"} price={400} visible={featureVisibility.kitchenSink} onFeatureToggle={() => { handleFeatureToggle('kitchenSink', !featureVisibility.kitchenSink) }} />
            <FeatureComponent feature={"First Wall"} price={500} visible={featureVisibility.firstWall} onFeatureToggle={() => { handleFeatureToggle('firstWall', !featureVisibility.firstWall) }} />
            <FeatureComponent feature={"Second Wall"} price={200} visible={featureVisibility.secondWall} onFeatureToggle={() => { handleFeatureToggle('secondWall', !featureVisibility.secondWall) }} />
            <FeatureComponent feature={"Third Wall"} price={200} visible={featureVisibility.thirdWall} onFeatureToggle={() => { handleFeatureToggle('thirdWall', !featureVisibility.thirdWall)}} />
            <FeatureComponent feature={"Fourth Wall"} price={200} visible={featureVisibility.fourthWall} onFeatureToggle={() => { handleFeatureToggle('fourthWall', !featureVisibility.fourthWall) }} />
            <FeatureComponent feature={"Customize Exterior (White or Black Frame free)"} price={500} visible={featureVisibility.customizeExterior} onFeatureToggle={() => { handleFeatureToggle('customizeExterior', !featureVisibility.customizeExterior) }} />
        </div>
    );
};

export default FeatureComponentList;