// FAQ.jsx
import React from 'react';

const FAQ = () => {
    return (
        <div className="faq">
            <div className="faq-content">
                <h2>Payment Terms</h2>
                <p>
                    At Outback Flats, we strive to make the purchasing process as clear and straightforward as possible. Here are the key payment terms for purchasing one of our tiny homes:
                </p>
                <ul>
                    <li>GST is not included in the listed prices. Designer provides estimates only.</li>
                    <li>A deposit of <strong>AUD 2000</strong> is required to secure your order.</li>
                    <li>An additional <strong>30% payment</strong> is due 2 weeks after the order is placed.</li>
                    <li>Another <strong>30% payment</strong> is required upon completion of the wall panel manufacturing.</li>
                    <li>The <strong>remaining balance</strong> is due when the flat is delivered to our Simmons and Clarke yard.</li>
                    <li>Please note that <strong>payments are non-refundable</strong>, as they are used to cover the costs of the production of your custom product.</li>
                </ul>

                <h2>Manufacturing and Delivery</h2>
                <ul>
                    <li>Small scratches or discoloration may occur during the manufacturing process.</li>
                    <li>We make every effort to deliver a high-quality product that meets your expectations.</li>
                    <li>Prices exclude assembly and delivery.</li>
                    <li>An assembly team can be arranged at an extra charge.</li>
                </ul>

                <h2>Additional Responsibilities</h2>
                <ul>
                    <li>The buyer is responsible for providing an electrician and plumber on site. Outback Flats can assist in coordinating these services as needed.</li>
                    <li>The buyer is also responsible for obtaining council approval or any building permits required.</li>
                    <li>Outback Flats will not be liable for any legal or regulatory issues that arise as a result of permitting.</li>
                </ul>
            </div>
        </div>
    );
};

export default FAQ;
