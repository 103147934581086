import { useState, useEffect } from "react";

const Return = () => {
  const [status, setStatus] = useState(null);
  const [customerEmail, setCustomerEmail] = useState('');
  const url = process.env.REACT_APP_API_URL

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');

    fetch(url + `/api/stripe/session-status?session_id=${sessionId}`)
      .then((res) => res.json())
      .then((data) => {
        setStatus(data.status);
        setCustomerEmail(data.customer_email);
      });
    // eslint-disable-next-line
  }, []);

  if (status === 'complete') {
    return (
      <section id="success" className="return_section">
        <h3>
          We appreciate your business! A confirmation email will be sent to {customerEmail}.

          If you have any questions, please email <a href="mailto:heathweeks2626@outlook.com">heathweeks2626@outlook.com</a>
        </h3>
      </section>
    )
  } else {
    return (
      <section className="return_section">
        <h3>
          Loading, please wait. If page does not refresh in 60 seconds, please contact Heath at heathweeks2626@outlook.com.
        </h3>
      </section>
    )
  }

}

export default Return;